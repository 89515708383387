import React from 'react';
import { config } from '../../constant';

const ContactInfo = () => {
    return (
        <>
            <div className="contact-info">
                <h4>Contacts</h4>
                <p>
                    At our architectural design studio, we're passionate about bringing your vision to life. Whether you
                    have a new construction project in mind or need help with renovations, we're here to help. Our team
                    of experts is ready to work with you every step of the way to ensure your project is a success.
                </p>
                <p>So why wait? Contact us today to start the conversation and let's turn your ideas into reality.</p>

                <ul>
                    <li className="media">
                        <i className="icon icon-map"></i>
                        <span className="media-body">{config.address}</span>
                    </li>
                    {/* End li */}

                    <li className="media">
                        <i className="icon icon-envelope"></i>
                        <span className="media-body">{config.email}</span>
                    </li>
                    {/* End li */}

                    <li className="media">
                        <i className="icon icon-phone"></i>
                        <span className="media-body">{config.phone}</span>
                    </li>
                    {/* End li */}
                </ul>
            </div>
            {/* End .contact-info */}
        </>
    );
};

export default ContactInfo;
