import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Gallery, Item } from 'react-photoswipe-gallery';
import 'photoswipe/dist/photoswipe.css';
import Masonry from 'react-masonry-css';
import { loadPanorama } from '../../utils/panoramaUtils';
import {
  breakpointColumnsObj,
  firstTab,
  fourthTab,
  secondTab,
  tabList,
  thiredTab,
} from "../../constant";
import Modal from "react-modal";

Modal.setAppElement("#root");

const PortfolioAnimation = () => {
  const [selectedScene, setSelectedScene] = useState();
  const [isPanoramaOpen, setPanoramaOpen] = useState(false);

  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState();
  
  const [loader, setLoader] = useState(false);

  function togglePanoramaOpen() {
    setPanoramaOpen(!isPanoramaOpen);
  }

  const handleVideo = (video) => {
    setIsVideoOpen(true);
    setSelectedVideo(video);
  };
  return (
    <>
      <div className="portfolio-filter-01">
        <Tabs>
          <TabList className="filter d-flex flex-wrap justify-content-start">
            {tabList.map((val, i) => (
              <Tab key={i}>{val}</Tab>
            ))}
          </TabList>

          <Gallery>
            <TabPanel key={0}>
              <div className="portfolio-content ">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {secondTab.map((val, i) => (
                    <div
                      className="portfolio-box-01"
                      key={i}
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay={val.delayAnimation}
                    >
                      <Item
                        original={val.img}
                        thumbnail={val.img}
                        width={750}
                        height={550}
                      >
                        {({ ref, open }) => (
                          <div className="portfolio-img">
                            <div className="portfolio-info" onClick={open}>
                              <h5>{val.title}</h5>
                              <span>{val.subTitle}</span>
                            </div>
                            <div className="gallery-link">
                              <img
                                src={val.img}
                                alt="Childhood"
                                role="button"
                                ref={ref}
                                onClick={open}
                                style={{ objectFit: "contain" }}
                              />
                            </div>
                          </div>
                        )}
                      </Item>
                    </div>
                  ))}
                </Masonry>
              </div>
            </TabPanel>
          </Gallery>

          <Gallery>
            <TabPanel key={1}>
              <div className="portfolio-content ">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {thiredTab.map((val, i) => (
                    <div
                      className="portfolio-box-01"
                      key={i}
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay={val.delayAnimation}
                    >
                      <Item
                        original={val.img}
                        thumbnail={val.img}
                        width={750}
                        height={550}
                      >
                        {({ ref, open }) => (
                          <div className="portfolio-img">
                            <div className="portfolio-info" onClick={open}>
                              <h5>{val.title}</h5>
                              <span>{val.subTitle}</span>
                            </div>
                            <div className="gallery-link">
                              <img
                                src={val.img}
                                alt="Childhood"
                                role="button"
                                ref={ref}
                                onClick={open}
                                style={{ objectFit: "contain" }}
                              />
                            </div>
                          </div>
                        )}
                      </Item>
                    </div>
                  ))}
                </Masonry>
              </div>
            </TabPanel>
          </Gallery>

          <TabPanel key={2}>
            <div className="portfolio-content ">
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {firstTab.map((val, i) => (
                  <div
                    className="portfolio-box-01"
                    key={i}
                    data-aos="fade-right"
                    data-aos-duration="1200"
                    data-aos-delay={val.delayAnimation}
                  >
                    <div
                      className="portfolio-img"
                      onClick={() => {
                        togglePanoramaOpen();
                        setSelectedScene({
                          name: val.title,
                          url: `/img/360View/original/${val.img}`,
                        });
                      }}
                    >
                      <div className="portfolio-info">
                        <h5>{val.title}</h5>
                        <span>{val.subTitle}</span>
                        <img
                          src="/img/360View/360-degrees.svg"
                          className="img-360-icon"
                          alt="360 icon img"
                        />
                      </div>
                      <Item original={val.img} thumbnail={val.img} width="70%">
                        {({ ref, open }) => (
                          <div className="gallery-link">
                            <img
                              src={`/img/360View/thumbnail/${val.img}`}
                              alt="Childhood"
                              role="button"
                              ref={ref}
                              onClick={() => {}}
                            />
                          </div>
                        )}
                      </Item>
                    </div>
                  </div>
                ))}
              </Masonry>
            </div>
          </TabPanel>

          <TabPanel key={3}>
            <div className="portfolio-content ">
              <div className="row mt-4">
                {fourthTab?.map((video, i) => (
                  <div
                    className="col-md-4 position-relative"
                    role="button"
                    key={i}
                    onClick={() => handleVideo(video)}
                  >
                    <i
                      className="fa fa-video-camera text-white position-absolute"
                      aria-hidden="true"
                      style={{
                        right: 0,
                        top: 0,
                        fontSize: "24px",
                        marginRight: "18px",
                        marginTop: "6px",
                      }}
                    ></i>
                    <img
                      src={video.img}
                      width="100%"
                      height="100%"
                      alt="walk through one"
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>

      <Modal
        isOpen={isPanoramaOpen}
        onRequestClose={togglePanoramaOpen}
        onAfterOpen={() => {
          loadPanorama(selectedScene, "#GParticleContainer", setLoader);
        }}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
        style={{ backgroundColor: "black" }}
      >
        <div>
          <button className="close-modal" onClick={togglePanoramaOpen}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            {loader && (
              <div className="loader-area">
                <div class="cssload-loader">Loading</div>
              </div>
            )}
            <div id="GParticleContainer" className="w-100 h-100"></div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isVideoOpen}
        onRequestClose={() => {
          setSelectedVideo(null);
          setIsVideoOpen(false);
        }}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button
            className="close-modal"
            onClick={() => {
              setSelectedVideo(null);
              setIsVideoOpen(false);
            }}
          >
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner" style={{ backgroundColor: "black" }}>
            <video
              width="100%"
              height="100%"
              style={{ objectFit: "contain" }}
              controls
              autoPlay
            >
              <source src={selectedVideo?.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PortfolioAnimation;
