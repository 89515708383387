import React from 'react';
import TextLoop from 'react-text-loop';
import { config } from '../../constant';

// const conctInfo = {
//     phone: '+04 6545-9535-6515',
//     email: ' nairobi-designer@domain.com',
// };

// const sliderContent = {
//     name: 'Nairobi Gadot',
//     designation: 'Full-stack Developer',
//     description: `I design and develop services for customers of all sizes,
//   specializing in creating stylish, modern websites, web
//   services and online stores.`,
//     btnText: ' Donwload CV',
// };

const Slider = () => {
    return (
        <>
            {/*  Home Banner */}
            <section id="home" className="home-banner">
                {/* <div className="hb-top-fixed d-flex">
                    <div className="hb-info">
                        <a href="tel:+04 6545-9535-6515">{conctInfo.phone}</a>
                        <a href="mailto:mail%20to:ibthemes21@gmail.com,com">{conctInfo.email}</a>
                    </div>
                    <div className="hb-lang">
                        <ul className="nav">
                            <li className="active">
                                <a href="#">EN</a>
                            </li>
                            <li>
                                <a href="#">FR</a>
                            </li>
                        </ul>
                    </div>
                </div> */}
                {/* End hp-top-fixed */}

                <div className="container">
                    <div className="row full-screen align-items-center">
                        <div className="col-lg-7">
                            <div className="type-box">
                                {/* <h6 data-aos="fade-up" data-aos-duration="1200">
                                    Hello, My name is
                                </h6> */}
                                <h1
                                    className="font-alt"
                                    data-aos="fade-up"
                                    data-aos-duration="1200"
                                    data-aos-delay="100"
                                >
                                    {config.title}
                                </h1>
                                <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                    <TextLoop>
                                        <p className="loop-text lead">Elevating Spaces for the Future</p>
                                        <p className="loop-text lead">Creating Visualize transcends for your space</p>
                                        <p className="loop-text lead">Creating Innovative Designs for modern living</p>
                                    </TextLoop>
                                </div>

                                <p className="desc" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                                    Thank you for visiting our expansive visualization, architectural, and design
                                    studio. We skillfully produce masterpieces to inspire you and bring your thoughts
                                    and desires to life.
                                </p>
                                {/* <div className="mt-4" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400">
                                    <a className="px-btn px-btn-white" href="img/resume.png" download>
                                        {sliderContent.btnText}
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Container*/}
                <div
                    className="hb-me"
                    style={{
                        backgroundImage: `url('/img/slider/banner-img.jpg')`,
                    }}
                ></div>
            </section>

            {/* End Home Banner  */}
        </>
    );
};

export default Slider;
