import React from 'react';
import Social from '../Social';
import Testimonials from '../testimonial/TestimonialAnimation';
import Services from '../service/ServiceAnimation';
// import Awards from '../award/AwardsAnimation';

const About = () => {
    return (
        <>
            <section id="about" className="section theme-light dark-bg">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-6 col-lg-4" data-aos="fade-up" data-aos-duration="1200">
                            <div className="about-me">
                                <div className="img">
                                    <div className="img-in">
                                        <img src="/img/exteriorGallery/10.jpg" alt="about" />
                                    </div>

                                    <Social />

                                    {/* End social icon */}
                                </div>
                                {/* End img */}
                                {/* <div className="info">
                                    <p>Ux/Ui Designer</p>
                                    <h3>Nairobi Gadot</h3>
                                </div> */}
                                {/* End info */}
                            </div>
                            {/* End about-me */}
                        </div>
                        {/* End col */}

                        <div
                            className="col-lg-7 ml-auto"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                            data-aos-delay="200"
                        >
                            <div className="about-info">
                                <div className="title">
                                    <h3>About GParticle</h3>
                                </div>

                                <div className="about-text">
                                    <p>
                                        At GParticle Studio, we bring your visions to life through stunningly realistic
                                        3D renderings and artist impressions. Our team of experienced artists and
                                        designers is dedicated to providing high-quality and customized solutions for a
                                        variety of industries, including architecture, real estate, interior design, and
                                        product visualization. With our cutting-edge technology and creative expertise,
                                        we strive to exceed our client's expectations and deliver exceptional results.
                                        Whether you're looking to showcase your latest architectural design or promote
                                        your new product, we're here to help you bring your ideas to life. Explore our
                                        portfolio and services to discover how we can help you transform your vision
                                        into reality.
                                    </p>
                                </div>
                                <div
                                    className="info-list"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <div className="about-counters__item">
                                        <h3>Project</h3>
                                        <span>
                                            <span id="js-counter-projects" className="counter-fonts">
                                                170+
                                            </span>
                                        </span>
                                    </div>
                                    <div className="about-counters__item">
                                        <h3>Countries</h3>
                                        <span id="js-counter-countries" className="counter-fonts">
                                            15+
                                        </span>
                                    </div>
                                    <div className="about-counters__item">
                                        <h3>Clients</h3>
                                        <span id="js-counter-clients" className="counter-fonts">
                                            117+
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End col */}
                    </div>

                    {/* separated */}

                    <div
                        className="separated"
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + 'img/border-dark.png'})`,
                        }}
                    ></div>

                    {/* End separated */}
                    <div className="title">
                        <h3>Services</h3>
                    </div>

                    <Services />

                    {/* End .row */}

                    {/* separated */}
                    {/* <div
                        className="separated"
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + 'img/border-dark.png'})`,
                        }}
                    ></div> */}
                    {/* End separated */}

                    {/* <div className="title">
                        <h3>Awards.</h3>
                    </div> */}

                    {/* <Awards /> */}
                    {/* End Awards */}

                    {/* separated */}
                    <div
                        className="separated"
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + 'img/border-dark.png'})`,
                        }}
                    ></div>
                    {/* End separated */}

                    <div className="title">
                        <h3>Testimonials.</h3>
                    </div>

                    <Testimonials />
                    {/* End Testimonaial */}
                </div>
            </section>
        </>
    );
};

export default About;
