import * as PANOLENS from 'panolens';
import * as THREE from 'three';

export const prepareViewer = (panoramaContainer) => {
  const container = document.querySelector(panoramaContainer);
  if (container) {
    container.innerHTML = '';

    return new PANOLENS.Viewer({
      container: container,
      autoHideInfospot: false,
      controlBar: true,
      controlButtons: ['fullscreen'], // default value -> ['fullscreen', 'setting', 'video']
      renderer: new THREE.WebGLRenderer({ alpha: true, antialias: true }),
      //enableReticle: true,
    });
  }
  return null;
};

export const loadPanorama = async (
  selectedScene,
  panoramaContainer,
  setLoader
) => {
  setLoader(true);
  if (selectedScene) {
    const viewer = prepareViewer(panoramaContainer);
    if (viewer) {
      const panorama = new PANOLENS.ImagePanorama(selectedScene.url);
      panorama.addEventListener('load', function (e) {
        setLoader(false);
      });
      panorama.name = selectedScene.name;
      viewer.add(panorama);
    }
  }
};
