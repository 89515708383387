import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function SimpleSlider() {
    const settings = {
        dots: true,
        arrow: false,
        infinite: true,
        speed: 900,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: false,
        margin: 30,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const TestimonilContent = [
      {
        imageName: "avadh_group.png",
        desc: `GParticle Studio is hands down the best architecture visualization company I've ever worked with. Their 3D rendering work is simply stunning, and they were able to bring my project to life in a way that I never thought possible.`,
        reviewerName: "",
        designation: "",
        delayAnimation: "",
      },
      {
        imageName: "shaligram_group.jpeg",
        desc: `If you're looking for the best in the business when it comes to architecture planning and visualization, look no further than GParticle Studio. They are true professionals who go above and beyond to exceed your expectations.`,
        reviewerName: "",
        designation: "",
        delayAnimation: "200",
      },
      {
        imageName: "pramukh_group.png",
        desc: `Working with GParticle Studio was an absolute pleasure. They took the time to understand my unique needs and requirements and then delivered a beautiful and accurate 3D rendering of my project.`,
        reviewerName: "",
        designation: "",
        delayAnimation: "400",
      },
      {
        imageName: "shreepad_group.png",
        desc: `I was blown away by the incredible level of detail and precision that GParticle Studio put into my architecture planning project. Their 3D rendering and visualization work is truly second to none.`,
        reviewerName: "",
        designation: "",
        delayAnimation: "400",
      },
    ];

    return (
      <div className="testimonial-wrapper">
        <Slider {...settings}>
          {TestimonilContent.map((val, i) => (
            <div
              key={i}
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay={val.delayAnimation}
            >
              <div className="testimonial-01 media">
                <div className="avatar">
                  <img
                    src={`img/testimonial/${val.imageName}`}
                    alt="review comments"
                  ></img>
                </div>
                <div className="media-body">
                  <p>{val.desc}</p>
                  <h6>{val.reviewerName}</h6>
                  <span>{val.designation}</span>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
}
