import React from 'react';
import {
    FaFacebookF,
    FaTwitter,
    FaInstagram,
    FaLinkedinIn,
    // FaPinterestP
} from 'react-icons/fa';
import { config } from '../constant';

const SocialShare = [
    { Social: <FaFacebookF />, link: config.socialLinks.facebook },
    { Social: <FaTwitter />, link: config.socialLinks.twitter },
    { Social: <FaInstagram />, link: config.socialLinks.instagram },
    { Social: <FaLinkedinIn />, link: config.socialLinks.linkedIn },
    // { Social: <FaPinterestP />, link: 'https://www.pinterest.com/' },
];

const Social = () => {
    return (
        <div className="nav social-icons justify-content-center">
            {SocialShare.map((val, i) => (
                <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank">
                    {val.Social}
                </a>
            ))}
        </div>
    );
};

export default Social;
