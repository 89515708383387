import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const News = () => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    const getData = () => {
      fetch("/blog/blog.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          setBlogs(myJson);
        });
    };
    getData();
  }, []);
  return (
    <>
      <div className="row">
        {blogs?.slice(0, 4).map((blog, idx) => (
          <div
            key={idx}
            className="col-md-6 m-15px-tb"
            data-aos={blog?.dataAos}
            data-aos-duration={blog?.dataAosDuration}
            data-aos-delay={blog?.dataAosDelay}
          >
            <div className="blog-grid">
              <div className="blog-img">
                <img src={blog?.image} alt="blog post"></img>
              </div>
              <div className="blog-info">
                <div className="meta">
                  {blog?.createdAt} - {blog?.author}
                </div>
                <h6>
                  <Link to={`/blog/${blog?.id}`} state={blog}>
                    {blog?.title}
                  </Link>
                </h6>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default News;
