export const config = {
    title: 'GParticle studio',
    email: 'contact@gparticlestudio.com',
    phone: '+91 97232 38432',
    address: '901-902, Orbit-2, Canal Road, Bharthana, Vesu, Surat, Gujarat, India-395007',
    socialLinks: {
        facebook: 'https://www.facebook.com/gparticle.studio',
        linkedIn: 'https://www.linkedin.com/company/gparticle-studio/',
        instagram: 'https://www.instagram.com/gparticle.studio/',
        twitter: 'https://twitter.com/gparticlestudio',
    },
};

export const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
};

export const tabList = ['Exterior Gallery', 'Interior Gallery', '360 View', 'video walkthrough'];
export const firstTab = [
    {
        img: 'BEDROOM1.jpg',
        width: 500,
        height: 500,
        title: 'Bedroom 1',
        subTitle:
            'The panoramic 360-degree view showcases a cozy queen-sized bed, framed by two bedside tables, while the walls are adorned with botanical prints, adding a touch of serenity to the overall ambiance.',
        alterText: '',
        delayAnimation: '',
        portfolioLink: '',
    },
    {
        img: 'BEDROOM2.jpg',
        width: 500,
        height: 500,
        title: 'Bedroom 2',
        subTitle:
            "The view of the bedroom reveals a spacious layout with a cozy bed as the centerpiece. The natural lighting enhances the room's warm ambiance and highlights the elegant decor.",
        alterText: '',
        delayAnimation: '100',
        portfolioLink: '',
    },
    {
        img: 'BEDROOM3.jpg',
        width: 500,
        height: 500,
        title: 'Bedroom 3',
        subTitle:
            'A welcoming and sophisticated space with luxurious bedding and elegant furniture pieces. The warm lighting adds a touch of intimacy and coziness to the overall atmosphere.',
        alterText: '',
        delayAnimation: '200',
        portfolioLink: '',
    },
    {
        img: 'BEDROOM4.jpg',
        width: 500,
        height: 500,
        title: 'Bedroom 4',
        subTitle:
            'The view of the bedroom captures the eye with its striking black-and-white color scheme, creating a modern and stylish look. The clean lines of the furniture and minimalistic decor add to the overall contemporary feel of the room.',
        alterText: '',
        delayAnimation: '0',
        portfolioLink: '',
    },
    {
        img: 'BEDROOM5.jpg',
        width: 500,
        height: 500,
        title: 'Bedroom 5',
        subTitle:
            'Reveals a tranquil oasis with soft, muted colors and minimalist decor that create a sense of calmness. The natural light flowing in through the windows further enhances the serene ambiance of the space.',
        alterText: '',
        delayAnimation: '100',
        portfolioLink: '',
    },
    {
        img: 'HALL.jpg',
        width: 500,
        height: 500,
        title: 'LIVING ROOM',
        subTitle:
            'The view of the living room showcases a stylish space with a harmonious blend of neutral tones and patterned accents, creating a cozy and inviting ambiance. The carefully selected decor pieces and soft lighting contribute to the overall warmth and comfort of the room.',
        alterText: '',
        delayAnimation: '200',
        portfolioLink: '',
    },
    {
        img: 'KITCHEN.jpg',
        width: 500,
        height: 500,
        title: 'KITCHEN AND DINING',
        subTitle:
            'The kitchen reveals a bright and airy space with clean lines and modern finishes. The minimalist design, blended with the ample natural light, creates a welcoming and refreshing atmosphere that is perfect for culinary creativity.',
        alterText: '',
        delayAnimation: '0',
        portfolioLink: '',
    },
    {
        img: 'SEATING_AREA.jpg',
        width: 500,
        height: 500,
        title: 'FAMILY SEATING ',
        subTitle:
            'A cozy and inviting space, with plush seating arrangements and warm lighting that create a relaxed and intimate atmosphere. The use of natural textures and earthy tones adds depth and character to the space, making it the perfect spot for quality family time and bonding.',
        alterText: '',
        delayAnimation: '100',
        portfolioLink: '',
    },
];

export const secondTab = [
    {
        img: '/img/exteriorGallery/2.jpg',
        width: 400,
        height: 700,
        title: 'Wide View',
        subTitle:
            'A stunning modern masterpiece, with sleek lines and contemporary finishes that exude sophistication and luxury. The surrounding lush greenery and the sparkling blue pool create a serene oasis that is both visually stunning and inviting.',
        alterText: '',
        delayAnimation: '100',
        portfolioLink: '',
    },
    {
        img: '/img/exteriorGallery/3.jpg',
        width: 400,
        height: 550,
        title: 'Landscape Layout',
        subTitle:
            'Immerses you in a lush urban retreat, with a harmonious blend of natural and man-made elements that create a tranquil oasis in the heart of the city. The carefully curated landscape design, featuring vibrant foliage and a stunning water feature, adds to the overall beauty and serenity of the space.',
        alterText: '',
        delayAnimation: '200',
        portfolioLink: '',
    },
    {
        img: '/img/exteriorGallery/4.jpg',
        width: 400,
        height: 400,
        title: 'Gazebo seatings',
        subTitle:
            'A stunning design with a spacious outdoor living area, featuring a charming gazebo that provides a cozy and shaded spot for relaxation and entertainment. The lush greenery and well-maintained landscaping create a serene ambiance that is perfect for unwinding and connecting with nature.',
        alterText: '',
        delayAnimation: '300',
        portfolioLink: '',
    },
    {
        img: '/img/exteriorGallery/5.jpg',
        width: 400,
        height: 400,
        title: 'DIRECTION GUIDE',
        subTitle:
            "with strategically placed directional signage that enhances the user experience and ensures seamless navigation throughout the space. The clean lines and modern finishes of the building's facade add to the overall aesthetic appeal of the property.",
        alterText: '',
        delayAnimation: '300',
        portfolioLink: '',
    },
    {
        img: '/img/exteriorGallery/6.jpg',
        width: 400,
        height: 400,
        title: 'Cricket Area',
        subTitle:
            'A vibrant and energetic space, designed to bring residents together and foster a strong sense of community through the sport of cricket. The lush green turf, well-maintained pitch, and modern amenities make it the perfect place for cricket enthusiasts to come together and engage in friendly competition.',
        alterText: '',
        delayAnimation: '300',
        portfolioLink: '',
    },
    {
        img: '/img/exteriorGallery/7.jpg',
        width: 400,
        height: 400,
        title: 'YOGA AREA',
        subTitle:
            "A peaceful and serene space, with lush greenery and calming natural elements that create the perfect atmosphere for yoga and meditation. The modern facilities and amenities cater to residents' physical and mental well-being, encouraging healthy habits and promoting a strong sense of community.",
        alterText: '',
        delayAnimation: '300',
        portfolioLink: '',
    },
    {
        img: '/img/exteriorGallery/8.jpg',
        width: 400,
        height: 400,
        title: 'SWIMMING POOL',
        subTitle:
            'A luxurious and inviting oasis, featuring a stunning infinity-edge pool surrounded by a spacious deck area that is perfect for lounging and soaking up the sun. The well-manicured landscaping and tranquil ambiance of the space make it the ideal spot for relaxation and leisurely enjoyment.',
        alterText: '',
        delayAnimation: '300',
        portfolioLink: '',
    },
    {
        img: '/img/exteriorGallery/9.jpg',
        width: 400,
        height: 400,
        title: 'KIDS PLAY AREA',
        subTitle:
            'A vibrant and colorful space that is designed to provide a safe and engaging environment for children to play and explore. The modern amenities and well-maintained equipment ensure that children are entertained and supervised, while parents can relax and enjoy the beautiful surroundings.',
        alterText: '',
        delayAnimation: '300',
        portfolioLink: '',
    },
    {
        img: '/img/exteriorGallery/10.jpg',
        width: 400,
        height: 400,
        title: 'RECEPTION AREA ',
        subTitle:
            "The sleek architecture and elegant design elements create a welcoming atmosphere that reflects the building's personality, making it the perfect space to greet and welcome residents and visitors alike.",
        alterText: '',
        delayAnimation: '300',
        portfolioLink: '',
    },
];

export const thiredTab = [
    {
        img: '/img/interiorGallery/p1.jpg',
        width: 400,
        height: 400,
        title: 'BEDROOM',
        subTitle:
            'A large, comfortable bed with plush pillows and blankets takes center stage, while natural light streams in from the windows, illuminating the space and creating a cozy atmosphere.',
        alterText: '',
        delayAnimation: '',
        portfolioLink: '',
    },
    {
        img: '/img/interiorGallery/p2.jpg',
        width: 400,
        height: 700,
        title: 'BEDROOM',
        subTitle:
            'A beautifully upholstered headboard adds a touch of elegance, while soft lighting and cozy textiles make the room a welcoming retreat at the end of the day.',
        alterText: '',
        delayAnimation: '100',
        portfolioLink: '',
    },
    {
        img: '/img/interiorGallery/p3.jpg',
        width: 400,
        height: 550,
        title: 'BEDROOM',
        subTitle:
            "A king-size bed with crisp white linens dominates the space, complemented by warm wood finishes and delicate floral accents, creating a tranquil and inviting atmosphere perfect for a good night's sleep.",
        alterText: '',
        delayAnimation: '200',
        portfolioLink: '',
    },
    {
        img: '/img/interiorGallery/p4.jpg',
        width: 400,
        height: 550,
        title: 'BEDROOM',
        subTitle:
            'This minimalist bedroom features a simple yet sophisticated design with a sleek platform bed, neutral tones, and geometric patterns, creating a contemporary and serene ambiance.',
        alterText: '',
        delayAnimation: '200',
        portfolioLink: '',
    },
];
export const fourthTab = [
    {
        img: '/video/walk_through_one.png',
        video: '/video/walk_through_one.mp4',
        width: 400,
        height: 400,
        delayAnimation: ""
    },
];