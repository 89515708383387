// import Contact from '../../components/contact/Contact';
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../header/Header";
const BlogDetails = () => {
  const location = useLocation();
  const propsData = location.state;
  const navigate = useNavigate();

  useEffect(() => {
    if (!propsData) {
      navigate("/");
    }
  }, [propsData, navigate]);

  if (!propsData) {
    return null;
  }

  return (
    <>
      <Header />
      <div className="py-5 ">
        <div className="container">
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src={propsData.image} alt="blog post"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>{propsData.title}</h2>
                    <div className="media">
                      <div className="avatar">
                        <img
                          src="/img/testimonial/team-1.jpg"
                          alt="thumbnail"
                        />
                      </div>
                      <div className="media-body">
                        <label>{propsData.author}</label>
                        <span>{propsData.createdAt}</span>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <p className="text-white">{propsData.description}</p>
                    <p className="text-white">
                      Eget aenean tellus venenatis. Donec odio tempus. Felis
                      arcu pretium metus nullam quam aenean sociis quis sem
                      neque vici libero. Venenatis nullam fringilla pretium
                      magnis aliquam nunc vulputate integer augue ultricies
                      cras. Eget viverra feugiat cras ut. Sit natoque montes
                      tempus ligula eget vitae pede rhoncus maecenas
                      consectetuer commodo condimentum aenean.
                    </p>
                    <h4 className="text-white">{propsData.subTitle}</h4>
                    <p className="text-white">{propsData.subDescription}</p>
                    <blockquote>
                      <p className="text-white">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam.
                      </p>
                      <p className="blockquote-footer text-white">
                        Someone famous in{" "}
                        <cite title="Source Title">Dick Grayson</cite>
                      </p>
                    </blockquote>
                    <p className="text-white">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </p>
                  </div>
                  {/* End article content */}
                  <ul className="nav tag-cloud">
                    {propsData.tags.map((tag, idx) => (
                      <li key={idx} href="#">
                        {tag}
                      </li>
                    ))}
                  </ul>
                  {/* End tag */}
                </article>
                {/* End Article */}

                {/* <div className="contact-form article-comment">
                                    <h4>Leave a Reply</h4>
                                    <Contact />
                                </div> */}
                {/* End .contact Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
