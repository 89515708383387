import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../header/Header";
import { Link } from "react-router-dom";
const BlogDetails = () => {
  const location = useLocation();
  const propsData = location.state;
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [limit, setLimit] = useState(10);
  const observer = useRef();
  useEffect(() => {
    const getData = () => {
      fetch("/blog/blog.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          setBlogs(myJson);
        });
    };
    getData();
  }, []);

  useEffect(() => {
    if (!propsData) {
      navigate("/");
    }
  }, [propsData, navigate]);

  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && blogs.length >= limit) {
          setLimit((prv) => prv + 10);
        }
      });
      if (node) observer.current.observe(node);
    },
    [blogs, limit]
  );

  if (!propsData) {
    return null;
  }
  return (
    <>
      <Header />
      <div className="py-1">
        <div className="container">
          {/* End close icon */}

          <section id="blog" className="section">
            <div className="container">
              <div className="title">
                <h3>Blogs</h3>
              </div>
              <div className="row">
                {blogs?.slice(0, limit).map((blog, idx) => {
                  if (idx + 1 === blogs?.slice(0, limit).length) {
                    return (
                      <div
                        ref={lastElementRef}
                        key={idx}
                        className="col-md-6 m-15px-tb"
                        data-aos={blog?.dataAos}
                        data-aos-duration={blog?.dataAosDuration}
                        data-aos-delay={blog?.dataAosDelay}
                      >
                        <div className="blog-grid">
                          <div className="blog-img">
                            <img src={blog?.image} alt="blog post"></img>
                          </div>
                          <div className="blog-info">
                            <div className="meta">
                              {blog?.createdAt} - {blog?.author}
                            </div>
                            <h6>
                              <Link to={`/blog/${blog?.id}`} state={blog}>
                                {blog?.title}
                              </Link>
                            </h6>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={idx}
                        className="col-md-6 m-15px-tb"
                        data-aos={blog?.dataAos}
                        data-aos-duration={blog?.dataAosDuration}
                        data-aos-delay={blog?.dataAosDelay}
                      >
                        <div className="blog-grid">
                          <div className="blog-img">
                            <img src={blog?.image} alt="blog post"></img>
                          </div>
                          <div className="blog-info">
                            <div className="meta">
                              {blog?.createdAt} - {blog?.author}
                            </div>
                            <h6>
                              <Link to={`/blog/${blog?.id}`} state={blog}>
                                {blog?.title}
                              </Link>
                            </h6>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
