import React from 'react';

const Map = () => {
    return (
        <>
            <div className="google-map">
                <div className="embed-responsive embed-responsive-21by9">
                    <iframe
                        className="embed-responsive-item"
                        title="location title"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.177508261124!2d72.78464911550043!3d21.14533298593541!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395f5b738b2be4b1%3A0xb71ee2ca04556c73!2sOdan%20Infotech%20LLP!5e0!3m2!1sen!2sin!4v1681275632305!5m2!1sen!2sin"
                    ></iframe>
                </div>
            </div>
            {/* End google-map */}
        </>
    );
};

export default Map;
