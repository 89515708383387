import React from 'react';
import {
    FaFacebookF,
    FaTwitter,
    FaInstagram,
    FaLinkedinIn,
    // FaPinterestP
} from 'react-icons/fa';
import { config } from '../../constant';

const SocialShare = [
    { Social: <FaFacebookF />, link: config.socialLinks.facebook },
    { Social: <FaTwitter />, link: config.socialLinks.twitter },
    { Social: <FaInstagram />, link: config.socialLinks.instagram },
    { Social: <FaLinkedinIn />, link: config.socialLinks.linkedIn },
    // { Social: <FaPinterestP />, link: 'https://www.pinterest.com/' },
];

const Footer = () => {
    return (
        <>
            <div className="row align-items-center">
                <div className="col-md-6 my-2">
                    <div className="nav justify-content-center justify-content-md-start">
                        {SocialShare.map((val, i) => (
                            <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank">
                                {val.Social}
                            </a>
                        ))}
                    </div>
                    {/* End .nav */}
                </div>
                {/* End .col */}

                <div className="col-md-6 my-2 text-center text-md-end">
                    <p>
                        © {new Date().getFullYear()} copyright{' '}
                        <a href="https://odaninfotech.com/" target="_blank" rel="noreferrer">
                            Odan infotech
                        </a>{' '}
                        all right reserved
                    </p>
                </div>
                {/* End .col */}
            </div>
            {/* End .row */}
        </>
    );
};

export default Footer;
